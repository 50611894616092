import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { reverse as _reverse } from 'lodash'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'
import {
  AdminEur1IdPayloadProps,
  AdminPurchaseResponse,
  AdminPurchaseResponseProps,
  AdminPurchaseType,
  CommentListResponse,
  CommentResponse,
  CustomFilesResponse,
  CustomFileType,
  PurchaseParamsPayloadProps,
  AdminPrintDatePayloadProps,
  InvoiceResponseType,
} from 'Types/admin/admin'
import { PurchaseAuctionHousePayload, WorkStatusType } from 'Types/global.types'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'
import { dateToApi } from 'Utils/form.util.ts'
import { PurchaseType } from 'Types/purchase'
import { UserTypes } from 'Types/user.types.ts'

interface UploadPurchasePayloadProps {
  options: FormData
  purchaseId: string
}

interface PurchaseAssignAdminProps {
  purchaseId: string
  adminId: string
}

interface PurchaseProformProps {
  options: FormData // eoProform: File
  purchaseId: string
}

interface PurchaseVehicleLicenceProps {
  options: FormData // vehicleLicence: File
  purchaseId: string
}

/**
 * Get list of purchases
 */
export const getAdminPurchases = createAsyncThunk(
  'admin/getPurchases',
  async ({
    param,
    sort = 'CREATED_AT',
    order,
    minDate,
    maxDate,
    pageIndex,
    pageSize,
    customsStatus,
  }: PurchaseParamsPayloadProps) => {
    let url = `${ADMIN_API_URL.PURCHASE.LIST}`
    const queryParams = []

    const formattedMinDate = dateToApi(minDate)
    const formattedMaxDate = dateToApi(maxDate)

    if (param) {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (minDate) {
      queryParams.push(`minDate=${encodeURIComponent(formattedMinDate)}`)
    }

    if (maxDate) {
      queryParams.push(`maxDate=${encodeURIComponent(formattedMaxDate)}`)
    }

    if (customsStatus) {
      queryParams.push(`status=${encodeURIComponent(customsStatus)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponseProps> = await axios.get(url, config)
      return {
        adminPurchaseList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

/**
 * Get admin purchase details
 */
export const getAdminPurchaseDetails = createAsyncThunk('admin/getPurchaseDetails', async (purchaseId: string) => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.get(url, config)
    return {
      purchaseDetails: data.data,
    }
  } catch (error) {
    handleError(error)
  }
})

/**
 * Upload purchase proform
 */
export const uploadPurchaseProform = createAsyncThunk(
  'admin/uploadProform',
  async ({ purchaseId, options }: PurchaseProformProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/eo-proform`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        adminPurchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload purchase car proform
 */
export const uploadPurchaseCarProform = createAsyncThunk(
  'admin/uploadCarProform',
  async ({ purchaseId, options }: PurchaseProformProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/proform`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        adminPurchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload purchase vehicleLicence
 */
export const uploadPurchaseVehicleLicence = createAsyncThunk(
  'admin/uploadPurchaseVehicleLicence',
  async ({ purchaseId, options }: PurchaseVehicleLicenceProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/vehicleLicence`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        adminPurchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Assign admin user to purchase
 */
export const assignAdminToPurchase = createAsyncThunk(
  'admin/assignAdmin',
  async ({ adminId, purchaseId }: PurchaseAssignAdminProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/assignee`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const body = {
      adminId,
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, body, config)
      return {
        adminPurchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const assignReviewerToPurchase = createAsyncThunk(
  'admin/assignReviewer',
  async ({ adminId, purchaseId }: PurchaseAssignAdminProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/reviewer`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const body = {
      adminId,
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, body, config)
      return {
        adminPurchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload custom file
 */
export const uploadCustomFile = createAsyncThunk(
  'admin/uploadCustomFile',
  async ({ purchaseId, options }: UploadPurchasePayloadProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/custom-file`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<{ data: CustomFileType }> = await axios.post(url, options, config)
      return {
        customFile: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get custom files by purchase ID
 * group: 'PURCHASE_DOCS' | 'USER_DOCS' | 'CAR_DOCS'
 */
export const getCustomFiles = createAsyncThunk(
  'admin/getCustomFiles',
  async ({ purchaseId, group }: { purchaseId: string; group: string }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/custom-file?group=${group}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomFilesResponse> = await axios.get(url, config)
      return {
        customFiles: data.data,
        group,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Download custom file by ID
 * @param file
 */
export const downloadCustomFile = async (file: string) => {
  const url = `${ADMIN_API_URL.DOWNLOAD}${file}`
  const token = getAccessToken()
  const config = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  }

  try {
    // @ts-ignore
    const data: AxiosResponse = await axios.get(url, config)
    return data
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Update purchase state
 */
export const updatePurchaseState = createAsyncThunk(
  'admin/updatePurchaseState',
  async ({ options, purchaseId }: { options: { state: WorkStatusType }; purchaseId: string }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/state`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseState: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update purchase payment state
 */
export const updatePurchasePaymentState = createAsyncThunk(
  'admin/updatePurchasePaymentState',
  async ({
    options,
    purchaseId,
  }: {
    options: { paymentState: 'PAID' | 'NOT_PAID' | 'FAILED' }
    purchaseId: string
  }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/payment-state`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchasePaymentState: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update purchase details
 */
export const updatePurchaseDetails = createAsyncThunk(
  'admin/updatePurchaseDetails',
  async ({ purchaseId, options }: { purchaseId: string; options: AdminPurchaseType }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/details`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update purchase EUR-1 id
 */
export const updatePurchaseEur1Id = createAsyncThunk(
  'admin/updatePurchaseEur1Id',
  async ({ options, purchaseId }: { purchaseId: string; options: AdminEur1IdPayloadProps }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/eur1`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update purchase print date
 */
export const updatePurchasePrintDate = createAsyncThunk(
  'admin/updatePurchasePrintDate',
  async ({ options, purchaseId }: { purchaseId: string; options: AdminPrintDatePayloadProps }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/print-date`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Handle upload document
 */
export const uploadUserDocument = createAsyncThunk(
  'admin/uploadUserDocument',
  async ({ purchaseId, options }: UploadPurchasePayloadProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/user-documents`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload EO EUR-1 document
 */
export const uploadEoEur1Document = createAsyncThunk(
  'admin/uploadEoEur1',
  async ({ purchaseId, options }: UploadPurchasePayloadProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/eo-eur1`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload COO document
 * @path param: purchaseId: string
 * @body param: coo: file
 */
export const uploadPurchaseCooDocument = createAsyncThunk(
  'admin/uploadPurchaseCooDocument',
  async ({ purchaseId, options }: { purchaseId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/coo`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload car document
 * body options: vehicleLicence, invoice, deliveryNote
 */
export const uploadCarDocument = createAsyncThunk(
  'admin/uploadCarDocument',
  async ({ purchaseId, options }: UploadPurchasePayloadProps) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/car-documents`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const uploadPurchaseSwift = createAsyncThunk(
  'admin/uploadPurchaseSwift',
  async ({ purchaseId, options }: { purchaseId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/swift`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.put(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove purchase
 */
export const removeAdminPurchase = createAsyncThunk('admin/removePurchase', async (purchaseId: string) => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: { deleted: boolean } }> = await axios.delete(url, config)
    return {
      purchaseId,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Post new comment
 * @param purchaseId
 * @param options
 */
export const postComment = createAsyncThunk(
  'admin/postPurchaseComment',
  async ({ purchaseId, options }: { purchaseId: string; options: { content: string } }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/comment`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CommentResponse> = await axios.post(url, options, config)
      return {
        comment: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get comments by purchase
 * @param purchaseId
 */
export const getComments = createAsyncThunk(
  'admin/getPurchaseComments',
  async ({ purchaseId, pageIndex, pageSize }: { purchaseId: string; pageIndex: number; pageSize: number }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/comment?page=${pageIndex}&limit=${pageSize}&order=DESC`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CommentListResponse> = await axios.get(url, config)
      const reverseData = _reverse(data.data)
      return {
        comments: reverseData,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

export const updateAuctionHouse = createAsyncThunk(
  'admin/updateAuctionHouse',
  async ({ purchaseId, options }: { purchaseId: string; options: PurchaseAuctionHousePayload }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/auction-house`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updatePurchaseUserInformation = createAsyncThunk(
  'admin/updatePurchaseUserInformation',
  async ({ purchaseId, options }: { purchaseId: string; options: UserTypes }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/user`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Send purchase email
 * @param purchaseId
 * @param options
 */
export const sendPurchaseEmail = async ({
  purchaseId,
  options,
}: {
  purchaseId: string
  options: { email: string }
}) => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/send-email`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: boolean }> = await axios.post(url, options, config)
    return {
      sent: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

export const getPurchaseInvoice = async (purchaseId: string) => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/invoice`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<InvoiceResponseType> = await axios.get(url, config)
    return {
      purchaseInvoice: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Generate purchase eur1
 * @param purchaseId
 */
export const generatePurchaseEur1 = createAsyncThunk('admin/generatePurchaseEur1', async (purchaseId: string) => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/generate-eur1`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, null, config)
    return {
      purchaseDetails: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Generate purchase EUR1
 */
export const generateCessionDate = createAsyncThunk(
  'admin/generateCessionDate',
  async ({ purchaseId, options }: { purchaseId: string; options: { cessionDate: string } }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/cession-date`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Generate attestation date
 */
export const generateAttestationDate = createAsyncThunk(
  'admin/generateAttestationDate',
  async ({ purchaseId, options }: { purchaseId: string; options: { attestationDate: string } }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/attestation-date`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, options, config)
      return {
        purchaseDetails: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Create a new purchase
 */
export const createPurchase = createAsyncThunk('admin/createPurchase', async () => {
  const url = `${ADMIN_API_URL.PURCHASE.ROOT}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: PurchaseType }> = await axios.post(url, null, config)
    return {
      purchase: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Set existing user to a purchase
 */
export const setUserToPurchase = createAsyncThunk(
  'admin/setUserToPurchase',
  async ({ purchaseId, userId }: { purchaseId: string; userId: string }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/user-id`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const options = {
      userId,
    }

    try {
      const { data }: AxiosResponse<{ data: PurchaseType }> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const setExportationCountry = createAsyncThunk(
  'admin/setExportationCountry',
  async ({ purchaseId, exportationCountry }: { purchaseId: string; exportationCountry: string }) => {
    const url = `${ADMIN_API_URL.PURCHASE.ROOT}/${purchaseId}/exportation-country`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const options = {
      exportationCountry,
    }

    try {
      const { data }: AxiosResponse<{ data: PurchaseType }> = await axios.patch(url, options, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
