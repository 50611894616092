import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CertificateType, PaginationProps } from 'Types/global.types.ts'
import { searchCustomsOrders } from 'Api/customs/customs.orders.service.ts'

interface InitialStateProps {
  customsOrderList: CertificateType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  customsOrderList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  param: '',
}

const customsOrdersSlice = createSlice({
  name: 'customsOrders',
  initialState,
  reducers: {
    setCustomsOrdersPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomsOrders.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOrderList = action.payload.orders
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchCustomsOrders.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCustomsOrders.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsOrdersSlice
export const { setCustomsOrdersPageIndex, setParam } = actions
export default customsOrdersSlice.reducer
