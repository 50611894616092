import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuctionHouseType } from 'Types/auctionHouse'
import {
  addAdminAuctionHouse,
  getAdminAuctionHouse,
  searchAdminAuctionHouse,
  updateAdminAuctionHouse,
} from 'Api/admin/admin.auction.service.ts'
import { PaginationProps } from 'Types/global.types.ts'

interface InitialState {
  auctionHouseList: AuctionHouseType[]
  total: number
  pageIndex: number
  loading: boolean
  loaded: boolean
  pageSize: number
  param: string
}

const initialState: InitialState = {
  auctionHouseList: [],
  total: 0,
  loading: false,
  loaded: false,
  pageIndex: 0,
  pageSize: 20,
  param: '',
}

const adminAuctionHouseSlice = createSlice({
  name: 'adminAuctionHouse',
  initialState,
  reducers: {
    setAuctionHousePageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAuctionHouse.fulfilled, (state, action) => {
        if (action.payload) {
          state.auctionHouseList = action.payload.adminAuctionHouseList
          state.total = action.payload.total
          state.loaded = true
        }
        state.loading = false
      })
      .addCase(getAdminAuctionHouse.pending, (state) => {
        state.loading = true
        state.loaded = false
      })
      .addCase(getAdminAuctionHouse.rejected, (state) => {
        state.loading = false
        state.loaded = false
      })
      .addCase(searchAdminAuctionHouse.fulfilled, (state, action) => {
        if (action.payload) {
          state.auctionHouseList = action.payload.adminAuctionHouseList
          state.total = action.payload.total
          state.pageIndex = 0
          state.loaded = true
        }
        state.loading = false
      })
      .addCase(searchAdminAuctionHouse.pending, (state) => {
        state.loading = true
        state.loaded = false
      })
      .addCase(searchAdminAuctionHouse.rejected, (state) => {
        state.loading = false
        state.loaded = false
      })
      .addCase(updateAdminAuctionHouse.fulfilled, (state, action) => {
        if (action.payload) {
          state.auctionHouseList = state.auctionHouseList.map((auctionHouse) => {
            if (auctionHouse.id === action.payload.adminAuctionHouse.id) {
              return action.payload.adminAuctionHouse
            }
            return auctionHouse
          })
        }
      })
      .addCase(addAdminAuctionHouse.fulfilled, (state, action) => {
        if (action.payload) {
          state.auctionHouseList.unshift(action.payload.adminAuctionHouse)
          state.total += 1
        }
      })
  },
})

const { actions } = adminAuctionHouseSlice
export const { setAuctionHousePageIndex, setParam } = actions
export default adminAuctionHouseSlice.reducer
