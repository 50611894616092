import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ADMIN_API_ROOT, ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { handleError } from 'Helpers/errors.helper.ts'
import { getAccessToken, getTempAccessToken, removeTempAccessToken, setTempAccessToken } from 'Utils/auth.util.ts'
import { setUserToken } from 'Helpers/auth.helpers.ts'
import { AdminListResponse, AdminResponse, AdminSignInResponse, AdminType } from 'Types/admin/admin'
import { AuthUserProps } from 'Types/onboarding.types.ts'

/**
 * Get Admin list
 */
export const getAdminList = createAsyncThunk('admin/adminList', async (deleted?: boolean) => {
  const url = `${ADMIN_API_ROOT}?deleted=${deleted}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminListResponse> = await axios.get(url, config)
    return {
      adminList: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const getAllAdminList = async (deleted: boolean = false) => {
  const url = `${ADMIN_API_ROOT}?deleted=${deleted}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminListResponse> = await axios.get(url, config)
    return {
      adminList: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Get current admin
 */
export const getAdmin = createAsyncThunk('admin/getAdmin', async () => {
  const url = `${ADMIN_API_ROOT}/profile`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminResponse> = await axios.get(url, config)
    return {
      admin: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Admin SignUp
 * @param body
 */
export const signUpAdmin = createAsyncThunk('admin/signUp', async (body: AdminType) => {
  const url = ADMIN_API_URL.AUTH.SIGNUP
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminResponse> = await axios.post(url, body, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Admin SignIn
 * @param options
 */
export const signInAdmin = async (options: AuthUserProps) => {
  const url = ADMIN_API_URL.AUTH.SIGNIN

  try {
    const { data }: AxiosResponse<AdminSignInResponse> = await axios.post(url, options)
    const { token } = data.data
    setTempAccessToken(token)
    return token
  } catch (error) {
    handleError(error, true)
  }
}

export const validateOtp = async (otpToken: string) => {
  const url = `${ADMIN_API_URL.AUTH.VALIDATE_OTP}?otp=${otpToken}`
  const token = getTempAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: string }> = await axios.get(url, config)
    const token: string = data.data
    removeTempAccessToken()
    setUserToken(token)
    return token
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Update Admin
 * @param body
 */
export const updateAdmin = createAsyncThunk('admin/updateAdmin', async (body: { [key: string]: any }) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminResponse> = await axios.patch(ADMIN_API_ROOT, body, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Remove admin
 * @param id
 */
export const removeAdmin = createAsyncThunk('admin/deleteAdmin', async (id: string) => {
  const url = `${ADMIN_API_ROOT}/${id}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<AdminResponse> = await axios.delete(url, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})
