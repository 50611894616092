import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CertificateType, GroupType } from 'Types/global.types.ts'
import { CustomFilesResponse } from 'Types/admin/admin'

import { CUSTOMS_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Asynchronous action to fetch a list of customs orders from the customs API.
 *
 * This action utilizes `createAsyncThunk` to handle asynchronous requests for retrieving customs orders
 * with support for query filtering, pagination, and error handling.
 *
 * @constant
 * @type {ThunkAction}
 * @param {Object} args - An object containing the query parameters.
 * @param {string} args.param - The filter parameter for searching customs orders.
 * @param {number} args.pageIndex - The current page index for pagination.
 * @param {number} args.pageSize - The number of items per page for pagination.
 * @returns {Promise<Object>} A Promise resolving to an object containing the orders and the total count of orders.
 * @throws Will handle errors encountered during the API request.
 */
export const searchCustomsOrders = createAsyncThunk(
  'customs/getCustomsOrders',
  async ({ param = '', pageIndex, pageSize }: { param: string; pageIndex: number; pageSize: number }) => {
    const url = `${CUSTOMS_API_ROOT}/certificate?param=${param}&page=${pageIndex}&limit=${pageSize}`

    try {
      const { data }: AxiosResponse<{ data: CertificateType[]; total: number }> = await axios.get(url, getAxiosConfig())
      return {
        orders: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * An asynchronous function created using createAsyncThunk to fetch a customs order based on the provided certificate ID.
 *
 * This function sends a GET request to the CUSTOMS_API_ROOT, retrieves the specific customs order details
 * associated with the provided certificate ID, and returns the data in a structured format.
 *
 * In the event of an error during the API call, the error is handled and logged using the handleError function.
 *
 * @param {string} certificateId - The unique identifier of the certificate for which the customs order details are retrieved.
 * @returns {Promise<{orderDetails: CertificateType}>} A promise that resolves with the customs order details encapsulated within an object.
 */
export const getCustomsOrder = createAsyncThunk('customs/getCustomsOrder', async (certificateId: string) => {
  const url = `${CUSTOMS_API_ROOT}/certificate/${certificateId}`

  try {
    const { data }: AxiosResponse<{ data: CertificateType }> = await axios.get(url, getAxiosConfig())
    return {
      orderDetails: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Redux thunk action for fetching custom files associated with a specific certificate.
 *
 * This function makes an asynchronous API call to retrieve the custom files
 * for a given certificate ID and group type. Upon successful response, it
 * returns an object containing the retrieved custom files and the group
 * information. If an error occurs during the request, the error is handled
 * appropriately.
 *
 * @constant
 * @type {AsyncThunk<{
 *   customFiles: CustomFilesResponse,
 *   group: GroupType
 * }, { certificateId: string, group: GroupType }, {}>}
 *
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.certificateId - The unique identifier for the certificate.
 * @param {GroupType} params.group - The group type associated with the custom files.
 * @returns {Promise<{ customFiles: CustomFilesResponse, group: GroupType }>} The response object containing the custom files and group.
 */
export const getCustomsCustomFiles = createAsyncThunk(
  'customs/getCustomsCustomFiles',
  async ({ certificateId, group }: { certificateId: string; group: GroupType }) => {
    const url = `${CUSTOMS_API_ROOT}/certificate/${certificateId}/custom-file?group=${group}`

    try {
      const { data }: AxiosResponse<{ data: CustomFilesResponse }> = await axios.get(url, getAxiosConfig())
      return {
        customFiles: data.data,
        group,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
