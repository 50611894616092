import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMS_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { SellerType } from 'Types/global.types.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

interface RequestOptionProps {
  param: string
  pageIndex: number
  pageSize: number
}

export const getCustomsSellers = createAsyncThunk(
  'customs/getCustomsSellers',
  async ({ param = '', pageIndex, pageSize }: RequestOptionProps) => {
    const url = `${CUSTOMS_API_ROOT}/seller?param=${param}&page=${pageIndex}&limit=${pageSize}`

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: SellerType[]; total: number }> = await axios.get(url, config)
      return {
        sellers: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const downloadCustomsDocument = async (filePath: string) => {
  const url = `${CUSTOMS_API_ROOT}/download${filePath}`
  const token = getAccessToken()
  const config = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  }

  try {
    // @ts-ignore
    const data: AxiosResponse = await axios.get(url, config)
    return data
  } catch (error) {
    handleError(error, true)
  }
}
