import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ADMIN_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { CustomsOfficerType } from 'Schemas/customs.types.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

type CustomsOfficerParams = {
  param: string
  pageSize: number
  pageIndex: number
}

/**
 * Get list of custom officers
 */
export const getCustomsOfficerList = createAsyncThunk(
  'admin/customsOfficerList',
  async ({ param, pageIndex, pageSize }: CustomsOfficerParams) => {
    let url = `${ADMIN_API_ROOT}/customs-officer`

    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: CustomsOfficerType[]; total: number }> = await axios.get(url, config)
      return {
        customsOfficerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Add new customs officer
 */
export const addCustomsOfficer = createAsyncThunk('admin/addCustomsOfficer', async (options) => {
  const url = `${ADMIN_API_ROOT}/customs-officer/signup`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: CustomsOfficerType }> = await axios.post(url, options, config)
    return {
      customsOfficer: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const updateCustomsOfficer = createAsyncThunk('admin/updateCustomsOfficer', async (options) => {
  const url = `${ADMIN_API_ROOT}/customs-officer`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: CustomsOfficerType }> = await axios.patch(url, options, config)
    return {
      customsOfficer: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Remove customs officer
 */
export const removeCustomsOfficer = createAsyncThunk('admin/removeCustomsOfficer', async (id: string) => {
  const url = `${ADMIN_API_ROOT}/customs-officer/${id}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: CustomsOfficerType }> = await axios.delete(url, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})
