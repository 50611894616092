import { createSlice } from '@reduxjs/toolkit'
import { CertificateType } from 'Types/global.types.ts'
import { getCustomsOrder } from 'Api/customs/customs.orders.service.ts'

interface InitialStateProps {
  customsOrderDetails: CertificateType
  loading: boolean
}

const initialState: InitialStateProps = {
  customsOrderDetails: null,
  loading: false,
}

const customsOrderDetailsSlice = createSlice({
  name: 'customsOrderDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOrderDetails = action.payload.orderDetails
          state.loading = false
        }
      })
      .addCase(getCustomsOrder.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCustomsOrder.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsOrderDetailsSlice
export const {} = actions
export default customsOrderDetailsSlice.reducer
