import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { PurchaseType } from 'Types/purchase'

import { CUSTOMS_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

export const searchCustomsPurchases = createAsyncThunk(
  'customs/searchCustomsPurchases',
  async ({ param = '', pageIndex, pageSize }: { param: string; pageIndex: number; pageSize: number }) => {
    const url = `${CUSTOMS_API_ROOT}/purchase/search?param=${param}&page=${pageIndex}&limit=${pageSize}`

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: PurchaseType[]; total: number }> = await axios.get(url, config)
      return {
        purchases: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCustomsPurchase = createAsyncThunk('customs/getCustomsPurchase', async (purchaseId: string) => {
  const url = `${CUSTOMS_API_ROOT}/purchase/${purchaseId}`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: PurchaseType }> = await axios.get(url, config)
    return {
      purchaseDetails: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
