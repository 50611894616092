import { createSlice } from '@reduxjs/toolkit'
import { getCustomsPurchase } from 'Api/customs/customs.purchases.service.ts'
import { PurchaseType } from 'Types/purchase'

interface InitialStateProps {
  customsPurchaseDetails: PurchaseType
  loading: boolean
}

const initialState: InitialStateProps = {
  customsPurchaseDetails: null,
  loading: false,
}

const customsPurchaseDetailsSlice = createSlice({
  name: 'customsPurchaseDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsPurchaseDetails = action.payload.purchaseDetails
          state.loading = false
        }
      })
      .addCase(getCustomsPurchase.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCustomsPurchase.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsPurchaseDetailsSlice
export const {} = actions
export default customsPurchaseDetailsSlice.reducer
