import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CustomsOfficerType } from 'Schemas/customs.types.ts'
import { AuthResponse } from 'Types/admin/admin'

import { CUSTOMS_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { handleError } from 'Helpers/errors.helper.ts'
import { setUserToken } from 'Helpers/auth.helpers.ts'
import { getAccessToken } from 'Utils/auth.util.ts'

interface AuthCustomsProps {
  email: string
  password: string
}

/**
 * Signin the customs officer
 * @param options
 */
export const signInCustoms = async (options: AuthCustomsProps) => {
  const url = `${CUSTOMS_API_ROOT}/auth/signin`

  try {
    const { data }: AxiosResponse<AuthResponse> = await axios.post(url, options)
    const { token } = data.data
    setUserToken(token)
    return token
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Get customs officer
 */
export const getCustomsOfficer = createAsyncThunk('customs/getCustomsOfficer', async () => {
  const url = `${CUSTOMS_API_ROOT}/profile`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: CustomsOfficerType }> = await axios.get(url, config)
    return {
      customsOfficer: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
