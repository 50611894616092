import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationProps } from 'Types/global.types.ts'
import { CustomsOfficerType } from 'Schemas/customs.types.ts'
import {
  addCustomsOfficer,
  getCustomsOfficerList,
  removeCustomsOfficer,
  updateCustomsOfficer,
} from 'Api/admin/admin.customsOfficer.service.ts'

interface InitialStateProps {
  customsOfficer: CustomsOfficerType | null
  initialCustomsOfficerList: CustomsOfficerType[]
  customsOfficerList: CustomsOfficerType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  customsOfficer: null,
  initialCustomsOfficerList: [],
  customsOfficerList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  param: '',
}

const customsOfficerListSlice = createSlice({
  name: 'customsOfficerList',
  initialState,
  reducers: {
    setCustomsOfficerListPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsOfficerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOfficerList = action.payload.customsOfficerList
          state.initialCustomsOfficerList = action.payload.customsOfficerList
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(getCustomsOfficerList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsOfficerList.rejected, (state) => {
        state.loading = false
      })
      .addCase(addCustomsOfficer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOfficerList.unshift(action.payload.customsOfficer)
          state.initialCustomsOfficerList.unshift(action.payload.customsOfficer)
        }
      })
      .addCase(updateCustomsOfficer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOfficerList = state.customsOfficerList.map((officer) => {
            if (officer.id === action.payload.customsOfficer.id) {
              return action.payload.customsOfficer
            }
            return officer
          })
        }
      })
      .addCase(removeCustomsOfficer.fulfilled, (state, action) => {
        if (action.payload) {
          const mutableList = state.customsOfficerList.filter((buyer) => buyer.id !== action.payload.id)

          state.customsOfficerList = mutableList as CustomsOfficerType[]
          state.initialCustomsOfficerList = mutableList as CustomsOfficerType[]
          state.total -= 1
        }
      })
  },
})

const { actions } = customsOfficerListSlice
export const { setCustomsOfficerListPageIndex, setParam } = actions
export default customsOfficerListSlice.reducer
