import { createSlice } from '@reduxjs/toolkit'

import { CustomsOfficerType } from 'Schemas/customs.types.ts'
import { getCustomsOfficer } from 'Api/customs/customs.auth.service.ts'

interface InitialStateProps {
  customsOfficer: CustomsOfficerType
  loading: boolean
}

const initialState: InitialStateProps = {
  customsOfficer: null,
  loading: false,
}

const customsOfficerSlice = createSlice({
  name: 'customsOfficer',
  initialState,
  reducers: {
    resetCustomsOfficer: (state) => {
      state.customsOfficer = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsOfficer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOfficer = action.payload.customsOfficer
          state.loading = false
        }
      })
      .addCase(getCustomsOfficer.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsOfficer.rejected, (state) => {
        state.loading = false
      })
  },
})

const { actions } = customsOfficerSlice
export const { resetCustomsOfficer } = actions
export default customsOfficerSlice.reducer
