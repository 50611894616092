import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AdminType } from 'Types/admin/admin'
import { PaginationProps } from 'Types/global.types.ts'
import { getAdmin, getAdminList, removeAdmin, signUpAdmin, updateAdmin } from 'Api/admin/admin.auth.service.ts'

interface InitialStateProps {
  admin: AdminType | null
  initialAdminList: AdminType[]
  adminList: AdminType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  admin: null,
  initialAdminList: [],
  adminList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
}

const adminListSlice = createSlice({
  name: 'adminList',
  initialState,
  reducers: {
    setAdminListPageSize: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    resetAdmin: (state) => {
      state.admin = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          state.admin = action.payload.admin
        }
      })
      .addCase(signUpAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminList.unshift(action.payload)
          state.initialAdminList.unshift(action.payload)
          state.total += 1
          state.pageIndex = 0
        }
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminList = action.payload.adminList
          state.initialAdminList = action.payload.adminList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getAdminList.pending, (state) => {
        state.loading = true
      })
      .addCase(getAdminList.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          const mutableAdminList: AdminType[] = state.adminList.map((admin) => {
            if (admin.id === action.payload.id) {
              return action.payload
            }
            return admin
          })

          state.adminList = mutableAdminList
          state.initialAdminList = mutableAdminList
        }
      })
      .addCase(removeAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          const mutableAdminList: AdminType[] = state.adminList.map((admin) => {
            if (admin.id === action.payload.id) {
              return action.payload
            }
            return admin
          })

          state.adminList = mutableAdminList
          state.initialAdminList = mutableAdminList
        }
      })
  },
})

const { actions } = adminListSlice
export const { setAdminListPageSize, resetAdmin } = actions
export default adminListSlice.reducer
