import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationProps } from 'Types/global.types.ts'
import { AdminPurchaseType } from 'Types/admin/admin'
import { searchCustomsPurchases } from 'Api/customs/customs.purchases.service.ts'

interface InitialStateProps {
  customsPurchaseList: AdminPurchaseType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  customsPurchaseList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  param: '',
}

const customsPurchasesSlice = createSlice({
  name: 'customsPurchases',
  initialState,
  reducers: {
    setCustomsPurchasesPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomsPurchases.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsPurchaseList = action.payload.purchases
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchCustomsPurchases.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCustomsPurchases.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsPurchasesSlice
export const { setCustomsPurchasesPageIndex, setParam } = actions
export default customsPurchasesSlice.reducer
